import React from 'react'

function Section2() {
  return (
    <section
      className=" row  px-2 px-sm-5 d-flex justify-content-center align-items-center  text-light my-5"
      style={{ minHeight: "100vh" }}
      id="about"
    >
      <div className="col-12 mb-5">
        <p className="text-start display-2 fw-semibold">
          {" "}
          ¿Qué es Healy?
        </p>

      </div>



      
        <div className="row my-5 d-flex">

          <div className="col text-center">
            <img src="../../healy2.png" style={{aspectRatio: "1/1", maxHeight:"350px"}} className=" " alt="" />
          </div>

          {/* <div className="col text-center">
            <img src="../../healy_mag.png" style={{aspectRatio: "1/1", maxHeight:"350px"}} className=" " alt="" />
          </div> */}

        </div>
      

      <div className="col-12 d-flex">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col-md-8  col-12 text-start">
            <p className="h3 fw-normal mb-3">Es un dispositivo portátil personal que analiza más de<strong> 9 millones de frecuencias</strong> y elige las que necesitas para <strong>armonizar tu campo bioenergético</strong>.</p>
            <p className="h5">
              ¿Te imaginas poder tener un dispositivo que en el momento en el que te sientes mal, te analizara y te dijera qué aplicarte para sentirte mejor?
            </p>
            <p className='h5 mb-5'> ¡Pues esto es parte de lo que hace Healy!</p>
          </div>
          <div className="col-md-3 col-12 text-start text-sm-end d-flex flex-column justify-content-end">
            <p className="h5 text-start text-sm-center mb-4">Conoce más:</p>
            <button className="btn btn-lg cta-btn me-2 mb-3"><a href="#shop" className='text-decoration-none text-light'>Quiero mi Healy</a></button>
            <button className="d-block btn btn-lg btn-outline-light  me-2 mb-3" data-bs-toggle="modal" data-bs-target="#modal-info-history">La Historia de Healy</button>
            <button className="d-block btn btn-lg btn-outline-light  me-2 mb-3" data-bs-toggle="modal" data-bs-target="#modal-info-fundamentos">Fundamentos Científicos</button>
            <button className="d-block btn btn-lg btn-outline-light  me-2 mb-3" data-bs-toggle="modal" data-bs-target="#modal-info-usos">Usos Principales de Healy</button>
            {/* <button className="d-block btn btn-outline-light btn-sm me-2 mb-3" data-bs-toggle="modal" data-bs-target="#modal-info-beneficios">Beneficios de Healy</button> */}
            <button className="d-block btn btn-lg btn-outline-light me-2 mb-3" data-bs-toggle="modal" data-bs-target="#modal-info-funciona">¿Cómo funciona?</button>
            <button className="d-block btn btn-lg text-light btn-sm me-2 mb-3" data-bs-toggle="modal" data-bs-target="#modal-info-disclaimer">Aviso</button>
          </div>
        </div>
      </div>

      {/* MODAL Historia de Healy */}
      <div className="modal" id='modal-info-history' >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "1000px" }}>
          <div className="modal-content text-dark p-2">
            <div className="modal-header">
              <p className='modal-title h1'>La Historia de Healy</p>
              <button className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mb-4">
                  <img src="https://mexico.healy.shop/wp-content/uploads/2023/03/400x-marcus-schmieke.png" style={{ maxHeight: "200px" }} alt="" />
                </div>
                <div className="col-12">
                  <p className='h3'>Marcus Schmieke</p>
                  <p className='h6'>El hombre detrás de la tecnología Healy</p>
                  <p className='small'>Marcus Schmieke es el fundador e inventor de Healy World, y el hombre que estableció la visión de la empresa cuando se lanzó en 2019. Desde entonces, Marcus se ha dedicado a desarrollar y desarrollar la tecnología diseñada para hacer que el bienestar holístico sea accesible para las personas de todo el mundo.</p>
                  <p className='small'>Graduado en física y filosofía en Heidelberg y Hannover, Marcus hizo un viaje a la India, donde vivió la vida de un monje en monasterios para obtener una visión profunda del lado espiritual del mundo y aprender sobre la conciencia. A partir de la inspiración que encontró en su experiencia, así como en los trabajos del físico alemán Burkhard Heim, el físico ruso Nikolai Kozyrev y otros científicos expertos, Marcus incorporó sus conocimientos y experiencia al concepto central que subyace a los productos Healy World.</p>
                  <p className='small'>Marcus es también el fundador de TimeWaver, además de dos institutos para la investigación de la conciencia: Institute for Applied Consciousness Research (IACR) & Existential Consciousness Research (ECR) Institute. Como testimonio de su dedicación en este campo, Marcus es autor de 25 libros en 10 idiomas sobre la conexión entre mente, materia y otros temas espirituales y científicos. A día de hoy, sigue dando conferencias sobre temas científicos, filosóficos y médicos, inspirando a miles de personas en todo el mundo en su camino hacia un estilo de vida más consciente.</p>
                </div>
                <hr />
                <div className="col-12">
                  <p className='h2'>Quiénes Somos</p>
                  <p className='small'>La historia de Healy World comenzó con el deseo de crear un mundo mejor para las personas que buscan una vida sana y plena. Reconocimos la necesidad de un cambio de mentalidad que diera prioridad al bienestar físico, mental y emocional. Así pues, nos propusimos tener un impacto positivo y continuo en la humanidad.</p>
                  <p className='small'>En colaboración con médicos, científicos e ingenieros, diseñamos productos que mejoran la vida y proporcionan frecuencias para su vida. Nuestras soluciones únicas tienen como objetivo fomentar el equilibrio, la confianza y la comodidad, permitiéndole sacar lo mejor de sí mismo.</p>
                  <p className='small'>Con la determinación de marcar la diferencia, nuestra misión es ayudar a las personas a prosperar hacia una vida de libertad, pasión y alegría.</p>
                </div>
              </div>



            </div>
            
          </div>
        </div>
      </div>
      {/* MODAL Fundamentos Científicos */}
      <div className="modal" id='modal-info-fundamentos' >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "1000px" }}>
          <div className="modal-content text-dark p-2">
            <div className="modal-header">
              <p className='modal-title h1'>Fundamentos Científicos</p>
              <button className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">


              <p className='h3 m-0'>Términos y Glosario.</p>
              <hr />
              <div className="row mb-3">
                <div className="col p-3 text-center">
                  <p className="h6">¿Qué es el campo bioenergético (BEF)?</p>
                  <p className="small">El flujo de energía vital que conecta cuerpo, mente y alma, tradicionalmente  conocido como Chi (o Qi) y praná.</p>
                  <p className='small'>En términos científicos: un flujo de información a nivel cuántico.</p>
                </div>
                <div className="col p-3 text-center">
                  <p className="h6">¿Qué son las Frecuencias Individualizadas de Microcorriente (IMF)?</p>
                  <p className="small">Las IMF son frecuencias que se analizan en  tiempo real, se modulan mediante microcorrientes y se aplican, por ejemplo, a través  de electrodos adhesivos.</p>
                </div>
                <div className="col p-3 text-center">
                  <p className="h6">¿Qué es el potencial de la membrana celular?</p>
                  <p className="small">Cada célula del cuerpo tiene su propio potencial de la membrana celular. El potencial  de la membrana en reposo es la diferencia entre el potencial eléctrico de las  matrices intracelular y extracelular de la célula.</p>
                </div>
              </div>
              <p className='h3 m-0'>Efectos de la Microcorriente.</p>
              <p className="small">(Según un estudio en animales de Ngok Cheng et al <sup>1</sup> )</p>
              <div className="row">
                <hr />
                <div className="col p-3 text-center">
                  <p className='fw-bold text-center'>Hasta 40% de aumento en el transporte de aminoácidos.</p>
                  <p className="small text-center">Los aminoácidos son los bloques  estructurales de la síntesis  proteica.</p>
                </div>
                <div className="col p-3 text-center">
                  <p className='fw-bold text-center'>Hasta 70% de aumento de la síntesis proteica.</p>
                  <p className="small text-center">La síntesis proteica es el proceso  por el que se forman proteínas  para crear células, es decir, la  creación de vida física de acuerdo  con el plan original guardado en  forma de ADN en cada una de  nuestras células.</p>
                </div>
                <div className="col p-3 text-center">
                  <p className='fw-bold text-center'>Hasta 500% de aumento en la producción de ATP.</p>
                  <p className="small text-center">El ATP (adenosín trifosfato) almacena  energía en las células de manera similar  a la gasolina en el depósito de un coche.  Es producido por las mitocondrias en la  célula. Las mitocondrias son las
                    «centrales eléctricas» que producen ATP  en cada célula del cuerpo.</p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <p className="small"><sup>1</sup> Clinical Orthopaedics and related research n.° 171, noviembre-diciembre de 1982. Este estudio en animales se refiere a los  efectos de la microcorriente y a los mecanismos básicos en los que se basan los programas de frecuencia de Healy.
                No incluyó ningún dispositivo Healy.</p>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL Usos Principales*/}
      <div className="modal" id='modal-info-usos' >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "1000px" }}>
          <div className="modal-content text-dark p-2">
            <div className="modal-header">
              <p className='modal-title h3'>Usos Principales de Healy</p>
              <button className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              {/* <p className='h4 '>Encuesta sobre la Aplicación.</p>
              <p className='mb-4'>¿Por qué razón usas healy?</p> */}
              <div className="row g-3">
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Dolor</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Regeneración</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Salud</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Estrés</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Dolor Crónico</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Dolor Agudo</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Problemas del Sueño</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Bienestar</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Molestias Estacionales</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Condición Física</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Piel</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Estado de Ánimo</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Aprehensión</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Aprendizaje</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Alergias</p>

                </div>
                <div className="col-sm-3 text-center p-4 border rounded-3 d-flex flex-column justify-content-center">
                  <p className="h3 m-0">Desintoxicación</p>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* MODAL Beneficios de Healy */}
      <div className="modal" id='modal-info-beneficios' >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "1000px" }}>
          <div className="modal-content text-dark p-2">
            <div className="modal-header">
              <p className='modal-title h3'>Beneficios de Healy</p>
              <button className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <p className=''>Lorem ipsum dolor sit amet.</p>
              <p className='m-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, laborum dolorem ipsa necessitatibus fugiat aspernatur quidem beatae id tempore minima soluta incidunt aut omnis quis ea eius aliquam perspiciatis numquam!.</p>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  Cómo Funciona*/}
      <div className="modal" id='modal-info-funciona' >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "1000px" }}>
          <div className="modal-content modal-info text-dark p-2">
            <div className="modal-header">
              <p className='modal-title h3'>¿Cómo funciona?</p>
              <button className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <p className=''>Healy opera con las Frecuencias Individualizadas de Microcorriente (IMF).</p>
              <hr />
              <div className="row">
                <div className="col text-center p-4">
                  <p className="h4">Analiza</p>
                  <p>Las frecuencias en el campo de información que necesitas ahora.</p>
                </div>
                <div className="col text-center p-4">
                  <p className="h4">Suminsitra</p>
                  <p>Las IMF necesarias para armonizar tu campo bioenergético (BEF).</p>
                </div>
              </div>
              <hr />
              <div className="row d-flex align-items-center">
                <div className="col text-center p-4">
                  <p>Y todo esto se realiza mediante electrodos que se conectan a tu Healy y entran en contacto con tu piel.</p>
                </div>
                <div className="col text-center">
                  <img src={"../../image59-111.jpeg"} alt="" className='img-fluid' style={{ maxHeight: "300px" }} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* MODAL Disclamer*/}
      <div className="modal" id='modal-info-disclaimer' >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "1000px" }}>
          <div className="modal-content modal-info text-dark p-2">
            <div className="modal-header">
              <p className='modal-title h3'>Aviso</p>
              <button className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body">
              <p className='h6'>Aviso:</p>
              <p className='small'>Frecuencias Individualizadas de Microcorriente (IMF) es una tecnología propia de Healy World. Utiliza los datos de un generador de ruido físico para asignar una  prioridad a las frecuencias que, de acuerdo con la experiencia profesional, tienen la máxima importancia para el usuario.</p>
              <p className='small'>
                La duración, frecuencias, intensidad, frecuencia, denominación y las descripciones de los programas Individualized Microcurrent Frequency (IMF) se basan en la  experiencia práctica de Nuno Nina y otros expertos y en nuestro conocimiento de la interacción de la microcorriente de frecuencia modulada con el Campo  Biogenergético. No han sido confirmados por estudios independientes.
              </p>
              <p className='small'>
                Los programas Individualized Microcurrent Frequency (IMF) de Healy son programas diseñados para favorecer el bienestar a través de la armonización del Campo  Bioenergético. Hemos realizado estudios a ciegas antes y después en grupos de participantes controlados y aleatorios, los cuales sugieren que el bienestar de los  participantes aumentó después de la aplicación. Atribuimos este hecho a la armonización del Campo Bioenergético, al que nos referimos como el flujo de energía  vital y que también tradicionalmente se conoce como Chi (o Qi) y Praná. Este hecho no ha sido confirmado por estudios independientes.
              </p>
              <p className='small'>
                Los programas Individualized Microcurrent Frequency (IMF) del dispositivo Healy no son aplicaciones médicas. No están destinados a curar, tratar, mitigar,  diagnosticar o prevenir enfermedades o cualquier trastorno médico. Los términos empleados en los programas no constituyen afirmaciones relativas a sus  efectos. El propósito de los programas es ofrecer al usuario una herramienta para explorar opciones de aplicación. Los programas Healy no deben considerarse  como sustitutos de asesoramientos, diagnósticos o tratamientos médicos. Dicho asesoramiento debe pedirse siempre a un profesional sanitario cualificado. Si  padeces o crees que padeces una enfermedad o si te estás sometiendo a un tratamiento médico, debes hablar con el profesional sanitario que te esté tratando  antes de utilizar Healy. Utiliza Healy siempre siguiendo las instrucciones de uso.
              </p>
              <hr />
              <p className='h6'>Exención de Responsabilidad:</p>
              <p className='small'>Los programas Individualized Microcurrent Frequency (IMF) del dispositivo Healy no están reconocidos por la medicina convencional debido a la falta de evidencia  científica en el sentido de la medicina convencional. La información contenida en estas páginas se ofrece únicamente con fines de referencia y educativos. Aunque  Healy World procura mantener la información de estas páginas actualizada, no puede ofrecer ninguna garantía respecto a la precisión, la idoneidad ni la  actualidad de esta. Healy World no se hace responsable de las pérdidas o los daños ocasionados por el uso de la información proporcionada en estas páginas.</p>
              <p className='small'>Toda la información se presenta «tal como es» y la responsabilidad de su interpretación y uso recae exclusivamente en el usuario. El uso de los productos,  programas o información de Healy World se realiza por cuenta y riesgo del usuario y está sujeto a su discreción y juicio independiente. Las empresas de Healy
                World declinan específicamente la responsabilidad por las decisiones del usuario relacionadas con el uso de los productos o servicios de Healy World que estén  fuera del alcance de las instrucciones de uso y del manual proporcionado por la empresa.
              </p>

            </div>

          </div>
        </div>
      </div>



    </section>
  )
}

export default Section2