import React from 'react'

function Section1() {
  return (
    <section
      className=" row   px-2 px-sm-5 d-flex justify-content-end align-items-center text-light mb-5"
      style={{ height: "90vh", width: "auto", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
      id="hero"
    >


      <div className="col-sm-6 col-12 text-light">
        <p className="fw-bold display-5 m-0 text-sm-start text-center mb-3">
          Si quieres encontrar los secretos del universo, piensa en términos de energía, frecuencia y vibración.
        </p>
        <p className="fw-light display-6 text-sm-start text-center mb-5">- Nikola Tesla</p>
        <div className="text-center text-sm-start">
          <a href="#about"><button className="btn cta-btn me-4">Conoce Healy</button>  </a>
          <a href="#shop"><button className="btn  btn-outline-light">Quiero mi Healy</button>  </a>
        </div>   
      </div>

      <div className="col-sm col-12 order-first order-sm-last text-end p-0">
        <img src="../../healy.png" className='img-fluid' />
      </div>

    </section>
  )
}

export default Section1