import "./styles.css";
import Header from "./components/Header";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section31 from "./components/Section31";
import Section32 from "./components/Section32";
import Section33 from "./components/Section33";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Footer from "./components/Footer";
import Section21 from "./components/Section21";
import Section52 from "./components/Section52";

import { useEffect, useState } from "react";

export default function App() {

  const [data, setData] = useState([])

//Lógica de Fetch
//if filter(i=> i.descuento).length >0   dibuja el banner
useEffect(() => {
  fetch(`https://us-central1.gcp.data.mongodb-api.com/app/healy-ckncs/endpoint/readProductos`)
      .then(response => response.json())
      .then(response => setData(response))
      

}, [])


const hayDescuentos = data.filter(i=>i.descuento).length > 0
const hayRegalos = data.filter(i=>i.regalos).length > 0
console.log(hayDescuentos);
console.log(hayRegalos);



  return (
    <div className="" id="body-fondo">
      { hayDescuentos && <div className="container-fluid mx-auto p-0 row d-flex justify-content-end">
        <div className="col p-0 text-end">
          <div className="alert alert-danger alert-dismissible fade show text-center " role="alert">
            <p className="m-0">¡Tenemos descuentos {hayRegalos && <span>y regalos</span>} para tí! <a href="#shop">¡Vamos!</a></p>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      </div> }

      <div className="container-fluid" style={{maxWidth: "1600px"}}>
      <Header />
        <Section1 />
        <Section2 />
        <Section21 />
        <Section33 />
        <Section4 />
        <Section5 />
        <Section52 />

        <Section6 />
      </div>

      <div className="container">
        
        
        
        {/* <Section3 /> */}
       
        
      </div>

      <div className="container-fluid">
        <Footer />
      </div>

      <a
        href={`https://wa.link/wcgyyl`}
        target="_blank"
        id="wa-btn"
        className="btn btn-success position-fixed rounded-5"
        style={{ bottom: 50, right: "1%" }}>
        <i className="fa-brands fa-whatsapp fs-1 p-2 m-0"> </i>
      </a>
    </div>
  );
}
