import React from 'react'

function Section6() {

  const faq = [
    
    {"p": "¿Healy es apto para mascotas?", "r": `Sí, healy tiene un grupo de programas para aplicar a animales. En la app de HealAdvisor Analyse, en el módulo de resonancia se puede realizar un análisis que indica los desequilibrios bioenergéticos de la mascota. 
    `},
    {"p": "¿Healy tiene certificaciones y estándares de calidad?", "r": `Sí. Healy está certificado en la UE (Certificado MEDCERT) y en USA (FDA) como dispositivo médico para el tratamiento del dolor crónico, la fibromialgia, el dolor esquelético y la migraña. También para el tratamiento de apoyo en trastornos anímicos como la depresión, la ansiedad o en insomnio.`},
    {"p": "¿Tiene garantía?", "r": `Healy cuenta con una garantía de 2 años.
    `},
    // {"p": "¿Cómo adquiero healy?", "r": `Healy lo adquieres a través de un Miembro Independiente de Healy World como nosotras. Para adquirirlo da click al siguiente enlace: <a href='#shop'>Productos</a>
    // `},
    {"p": "¿Cuáles son los métodos de pago?", "r": `Los métodos de pago dependen del país donde residas. En México se aceptan pagos con tarjeta de débito, crédito, spei y paypal. Antes de adquirirlo pregunta por las promociones a meses sin intereses.`},
    {"p": "¿En cuánto tiempo llega healy a partir de que lo pida?", "r": `Tarda 3-5 días hábiles en llegar a tu domicilio. En México el envío es gratuito y se envía por DHL.
    `},
    {"p": "¿Cuáles son las restricciones de uso de healy?", "r": `La terapia con microrrientes no se pueda aplicar en mujeres embarazadas, personas que tengan marcapasos o sufran de epilepsia. Tampoco se pueden colocar los electrodos adhesivos en heridas abiertas o zonas del cuerpo que tengan placas metálicas. Pero, en todos estos casos, las personas, sí podrán recibir la terapia de frecuencias sin el uso de las microcorrientes, es decir, a través de la app healadvisor analyse que se incluye en el healy resonance, resonance plus y profesional.
    `},
    {"p": "¿Cuánto tiempo dura la batería de healy?", "r": `Su duración varía según los programas utilizados. Si se ocupa con microcorrientes la batería puede durar entre 5 y 7hrs. Si se ocupa con el módulo de análisis de resonancia, entonces puede durar más tiempo.`},
    {"p": "¿Qué adaptadores se pueden usar para cargar healy?", "r": `Adaptadores cuyo OUTPUT/SALIDA sea de 5V (mínimo 4.4V) - 2A (máximo 2.5A). 
    `},
    {"p": "¿Puedo usar pulseras y/o electrodos de otras marcas distintas a healy?", "r": `Para garantizar un rendimiento seguro y óptimo debes usar accesorios comprados en la tienda de Healy preferentemente.
    `},
    {"p": "¿Cómo voy a aprender a usar mi dispositivo?", "r": `Al adquirir healy, la empresa te da acceso a Healy Academy donde vienen videos tutoriales para comenzar a utilizar el dispositivo. Adicionalmente, nosotros te damos el manual en pdf. Te damos acceso a capacitaciones gratuitas por zoom para usuarios principiantes, intermedios y avanzados. Además te integramos a los grupos de apoyo en whatsapp y telegram para que te ¡unas a la comunidad de Latinoamérica más grande de healy!
    `},
    {"p": " ¿Puedo usar mi healy en más de un celular o tableta?", "r": `Sí. Sin embargo, ten en cuenta que la información guardada de los análisis y del cliente se queda en la aplicación del celular donde está instalada la app.
    `},
    {"p": "¿Puedo distribuir healy y comenzar mi negocio?", "r": `Sí, nosotros te capacitamos y apoyamos para que comiences tu negocio con healy ¡y empieces a cambiar vidas!
    `},
  ]
  return (
    <section
      className=" row px-2 px-sm-5 d-flex flex-column justify-content-start
     align-items-center my-5"
      id="faq"
      style={{ minHeight: "90vh" }}
    >
      <div className="col-12">
      <p className="text-start text-light display-2 fw-semibold my-5">
            FAQ - Preguntas Frecuentes
          </p>
      </div>

      <div className="col-12">
        <div className="accordion" id="accordionExample">

          <div className="accordion-item">
            <p className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"

              >
                <p className="h4 m-0">1) ¿Para quién es healy?</p>
              </button>
            </p>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className='fs-5 fw-light'>Healy es para todos. Para niños, adolescentes, adultos y adultos mayores. Para amas de casa, atletas, terapeutas, coaches, emprendedores, networkers, etc.</p>
                <p className='fs-5 fw-light'>Se puede realizar envío de frecuencias de forma individual o grupal e incluso se pueden enviar frecuencias a casas, edificios, objetos y plantas.</p>
              </div>
            </div>
          </div>

          {faq.map((item,key)=>{
            
            return(
              <div className="accordion-item" key={key}>
            <p className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse-${key}`}
                aria-expanded="false"
                aria-controls={`collapse-${key}`}

              >
                <p className="h4 m-0">{`${key+2}) `}{item.p}</p>
              </button>
            </p>
            <div
              id={`collapse-${key}`}
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className='fs-5 fw-light'>{item.r}</p>
              </div>
            </div>
          </div>
            )
          })}
          
          
        </div>
      </div>
    </section>
  )
}

export default Section6