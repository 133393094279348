import React, { useEffect, useState } from 'react'

function Section5() {

  


  return (
    <section
      className=" row d-flex flex-column justify-content-center px-2 px-sm-5 my-5"
      style={{ minHeight: "90vh" }}
      id="eventos"
    >
      <div className="col-12">
        <p className="text-start text-light display-2 fw-semibold my-5">
          Eventos
        </p>
      </div>

      <div className=" col-12 rounded-4 text-light d-flex align-items-center justify-content-center flex-column text-center p-5" style={{ backgroundImage:"linear-gradient(200.55deg, #24CFC5 0%, #001C63 100%)" }}>

        <p className="h2 mb-3">Visita nuestro canal de Telegram</p>
        <p className="">¡Entérate de los próximos talleres, pláticas y retos para que conozcas Healy y MagHealy!</p>
        <p className="mb-5">¡Además de noticias y mucho más!</p>


        <a href="https://t.me/eventoshealy" target={"_blank"}>
          <button className='btn cta-btn btn-lg'><i className="fa-solid fa-paper-plane"></i> /eventoshealy</button>
        </a>
      </div>


      


    </section>
  )
}

export default Section5