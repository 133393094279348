import React from 'react'

function Section21() {
  return (
    <section
      className=" row  px-2 px-sm-5 d-flex justify-content-center align-items-center flex-column text-light my-5"
      style={{ minHeight: "100vh" }}
      id="about-us"
    >

      <div className="row d-flex align-items-center justify-content-between">
        <div className="col-12 my-5 my-sm-5">
          <p className="text-start display-2 fw-semibold mb-0">
            Nosotras
          </p>
        </div>

        {/* <div className="col-12 text-center align-self-stretch mb-5">
          <img className='img-fluid rounded-4' src={"https://source.unsplash.com/random?family"} style={{ aspectRatio: "16 / 9", objectFit: "cover", maxHeight: "600px" }} alt="" />
        </div> */}

        <div className="col-12 text-center">
          <p className='h3 mb-3'>Somos las hermanas Claudia y Diana Tejeda. Ambas somos psicólogas y trabajamos juntas en <span className="fst-italic">Nuestro Heal-y en Español</span>.
          </p>
          <p className='h5 mb-5'>¡Te damos la más cordial bienvenida!</p>
        </div>

        <div className="col-sm-6 col-12 text-center mb-5 mb-sm-0">
          <figure className="figure">
            <img src="../../claudia.png" className="figure-img img-fluid rounded" 
            width="100%"
            style={{
                    aspectRatio: "1/0.9",
                    objectFit: "cover",
                    objectPosition: "0 0px",
                    maxHeight: "450px"
                  }}
            />
              <figcaption className=" text-light h1 text-center">Psic. Claudia Tejeda</figcaption>
          </figure>
          <p className='h4'>Facilitadora de Constelaciones Familiares, Terapia de Abrazo, Regresiones a vidas pasadas, thetahealer, Reiki Master, Método Yuen y otras técnicas de liberación emocional. Cuenta con más de 20 años de experiencia profesional trabajando de manera individual y grupal. </p>
        </div>
        <div className="col-sm-6 col-12 text-center">
        <figure className="figure">
            <img src="../../diana.png" className="figure-img img-fluid rounded" 
            width="100%"
            style={{
                    aspectRatio: "1/0.9",
                    objectFit: "cover",
                    objectPosition: "0 0px",
                    maxHeight: "450px"
                  }}
            />
              <figcaption className="h1 text-light text-center">Psic. Diana Tejeda</figcaption>
          </figure>
          <p className='h4'>Facilitadora de procesos de cambio con Programación Neurolingüística y de EFT Tapping, Método Yuen. Estudiante de Medicina cuántica, emprendedora desde el 2016 y Asesora de inglés con más de 10 años de experiencia.</p>
        </div>

      </div>
    </section>
  )
}

export default Section21