import React from 'react'

function Footer() {
    return (
        <footer className="row text-bg-dark p-4 p-sm-5">
            <div className="col-12 mb-5 ">
                <p className="h2 text-center">¿Estás list@ para que las frecuencias te lleven a un estado de mayor bienestar?</p>
                
            </div>
            {/* <div className="col-12 col-sm mb-5 text-start">
                <p className="h5 fw-bold"><i className="fa-solid fa-heart-pulse"></i> Healy</p>
                <hr />
                <p className='small fw-light m-0'>¿Qué es Healy?</p>
                <p className='small fw-light m-0'>Tiendas</p>
                <p className='small fw-light m-0'>Testimonios</p>
                <p className='small fw-light m-0'>Blog</p>
                <p className='small fw-light m-0'>FAQ</p>
            </div>
            <div className="col-12 col-sm mb-5 text-start">
                <p className="h5 fw-bold">Salud</p>
                <hr />
                <p className='small fw-light m-0 '>La ciencia detrás de Healy</p>
                <p className='small fw-light m-0'>10 maneras saludables de comer</p>

            </div>
            <div className="col-12 col-sm mb-3 text-start">
                <p className="h5 fw-bold">Enlaces</p>
                <hr />
                <p className='small fw-light m-0'>Enlace 1</p>
                <p className='small fw-light m-0'>Enlace 1</p>
                <p className='small fw-light m-0'>Enlace 1</p>

            </div> */}

            <div className="col-12 mb-5 text-center d-flex justify-content-center">
                <a href="#shop"><button className='btn btn-lg cta-btn mb-5 me-4'>¡Quiero mi healy!</button></a>
            </div>

            <div className="col-12 d-flex flex-column align-items-center justify-content-center mb-5">
            <p className="small">Síguenos en: </p>
                <div className="">
                    <a href="https://www.facebook.com/nuestrohealyesp" target={"_blank"}><button className='btn btn-primary me-2'><i className="fa-brands fa-facebook"></i></button></a>
                    <a href="https://www.youtube.com/channel/UCw_fnuK493-ms8R5qXDi7bQ
                    " target={"_blank"}><button className='btn btn-danger me-2' style={{ backgroundColor: "red" }}><i className="fa-brands fa-youtube"></i></button></a>
                    <a href="https://t.me/eventoshealy
                    " target={"_blank"}><button className='btn btn-primary me-2' style={{ backgroundColor: "dodgerBlue" }}><i className="fa-solid fa-paper-plane"></i></button></a>
                </div>

            </div>

            <div className="col-12 text-center mb-0">
                <hr />
                <p className="small fw-light">powered by Sirius-Tec | 2023</p>
            </div>
        </footer>
    )
}

export default Footer