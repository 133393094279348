import React from 'react'

function Section4() {
  return (
    <section
        className=" row  px-2 px-sm-5 d-flex justify-content-center align-items-center my-5"
        style={{ minHeight: "90vh" }}
        id="testimony"
      >
        <div className="col-12 my-auto align-self-stretch">
        <p className="text-start display-2 text-light fw-semibold my-5">
            Testimonios
          </p>
          

          <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">

              <div className="carousel-item active" data-bs-interval="4500">
                <div className="card border-0">
                <iframe  src="https://www.youtube.com/embed/QQDAlnUDETg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" style={{aspectRatio: "16/9", maxHeight: "600px", maxWidth: "6000px"}}></iframe>
                <div className="card-body">
                    <figure className="text-center">
                      <blockquote className="blockquote">
                        <p>
                        Testimonio dado por la Terapeuta Patricia Morato.
                        </p>
                      </blockquote>
                      <figcaption className="blockquote-footer">
                      https://www.youtube.com/@nuestrohealyenespanol2760
                        
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>

              <div className="carousel-item" data-bs-interval="4500">
                <div className="card border-0">
                <iframe className='' src="https://player.vimeo.com/video/833886712?h=dc3a35bbc3" style={{aspectRatio: "16/9"}} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                <div className="card-body">
                    <figure className="text-center">
                      <blockquote className="blockquote">
                        <p>
                        Testimonio dado por la Terapeuta Patricia Morato.
                        </p>
                      </blockquote>
                      <figcaption className="blockquote-footer">
                      https://vimeo.com/833886712/dc3a35bbc3
                        
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>

              <div className="carousel-item" data-bs-interval="4500">
                <div className="card border-0">
                <iframe  src="https://www.youtube.com/embed/mX4PyjLdItk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" style={{aspectRatio: "16/9"}}></iframe>
                <div className="card-body">
                    <figure className="text-center">
                      <blockquote className="blockquote">
                        <p>
                        Testimonio sobre el uso de Healy en mascotas.
                        </p>
                      </blockquote>
                      <figcaption className="blockquote-footer">
                      https://www.youtube.com/shorts/mX4PyjLdItk
                        
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
  )
}

export default Section4