import React, { useEffect, useState } from 'react'

function Section33() {

    const [data, setData] = useState([])
    const [sItem, setSItem] = useState(null)


    useEffect(() => {
        fetch(`https://us-central1.gcp.data.mongodb-api.com/app/healy-ckncs/endpoint/readProductos`)
            .then(response => response.json())
            .then(response => setData(response))
            .then(() => setSItem(data[0]))

    }, [])





    return (
        <section className='row px-2 px-sm-5 d-flex justify-content-center align-items-center flex-column text-light my-5'
            style={{ minHeight: "100vh" }}
            id="shop">

            <div className="row my-5">
                <p className="text-start display-2 fw-semibold mb-5">Productos</p>
                <p className='h3'>¡Encuentra el Healy perfecto para tí!</p>

            </div>

            <div className="row d-flex justify-content-center mb-5">

                {data.map((item, key) => {
                    return (
                        <div className="col-4 mb-4 d-flex justify-content-center" key={key}>
                            <div className='col-8 col-md-4 mb-5 rounded-3 nav-link position-relative'
                                onClick={() => { setSItem(item) }}
                                style={{
                                    backgroundImage: `url("../../${item.título}.jpg")`,
                                    aspectRatio: "1/1",
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat"
                                }}
                                key={key}>
                                   { item.descuento && <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill text-bg-danger fs-6 border z-3'>{item.descuento}% OFF</span>}

                                    <p className='position-absolute top-100 start-0 fs-5 mt-2 lh-1'>{item.título}</p>
                            
                            </div>
                        </div>
                    )
                })}

            </div>


            <div className="row d-flex justify-content-between align-items-center mt-md-5">
                {!sItem && <p className='h4'>Haz clic en las imágenes para ver más detalles.</p>}

                <div className="col-12 col-sm-5 mb-5 mb-sm-0 text-start p-0">

                    {sItem &&
                        <img src={`../../${sItem.título}.jpg`}
                            className="img-fluid rounded-4"
                            style={{ aspectRatio: "1/1" }}
                        />}
                </div>
                {sItem && <div className="col-12 col-sm-6 p-2">
                    <div className="alert alert-light">
                        <div className="d-flex align-items-center mb-0">
                            <p className="h1">{sItem.título}</p>
                            {sItem.descuento && <p className='ms-2 badge fs-3 cta-btn'>{sItem.descuento}% OFF</p>}

                        </div>

                        <p className='mb-4 h4'>{sItem.subtítulo}</p>

                        <div className="row ">
                            <div className="col text-center ">
                                <p className='h6 m-0'>
                                    <span className={sItem.descuento && `text-decoration-line-through text-secondary`}>MXN {sItem.precio.pesos} </span>
                                </p>
                                {sItem.descuento && <p className='h3'> MXN {((Number(sItem.precio.pesos) * (100 - sItem.descuento)) / 100).toFixed(2)}</p>}
                            </div>
                            <div className="col text-center">
                                <p className='h6 m-0'>
                                    <span className={sItem.descuento && `text-decoration-line-through text-secondary`}>USD {sItem.precio.dólares} </span>
                                </p>
                                {sItem.descuento && <p className='h3'> USD {((Number(sItem.precio.dólares) * (100 - sItem.descuento)) / 100).toFixed(2)}</p>}
                            </div>
                            <div className="col text-center">
                                <p className='h6 m-0'>
                                    <span className={sItem.descuento && `text-decoration-line-through text-secondary`}>EUR {sItem.precio.euros} </span>
                                </p>
                                {sItem.descuento && <p className='h3'> EUR {((Number(sItem.precio.euros) * (100 - sItem.descuento)) / 100).toFixed(2)}</p>}
                            </div>
                        </div>
                        <div className="my-3">
                            <ul className='h4'>
                                {sItem.descripción.split(",").map((element, key) => {
                                    return (
                                        <li key={key}>{element}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>

                    {sItem.regalos &&
                        <div className='alert alert-success'>
                            <p className='h4 m-0 fw-bold'>Regalos:</p>
                            <ul className='h5'>
                                {sItem.regalos.split(",").map((element, key) => {
                                    return (
                                        <li key={key}>{element}</li>
                                    )
                                })}
                            </ul>
                        </div>}

                    <div className="alert alert-primary">
                        <p className="h4 m-0 fw-bold">Recuerda que al adquirir tu Healy / MagHealy con nosotras, te incluimos:</p>
                        <ul className='h5'>
                            <li>Manual de uso de Healy</li>
                            <li>Video y Manual de Primeros Pasos</li>
                            <li>Acceso a los grupos de WhatsApp y Telegram</li>
                            <li>Acceso a capacitaciones via Zoom para principiantes, usuarios intermedios y avanzados.</li>
                            <li>Programa de Apoyo para la recuperación de tu inversión</li>
                        </ul>
                    </div>

                   

                    <div className="row px-3">

                        <button className='btn fs-4 btn-lg cta-btn me-3' data-bs-toggle="modal" data-bs-target="#staticBackdrop">¿Cómo comprar?</button>
                    </div>
                </div>}
            </div>


            {/* MODAL CÓMO COMPRAR */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "1000px" }}>
                    <div className="modal-content text-dark">
                        <div className="modal-header">
                            <h1 className="modal-title h2" id="staticBackdropLabel">¿Cómo comprar?</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row fs-5">
                                <div className="col">
                                    <p className="h4">Sigue estas instrucciones:</p>
                                    <hr />
                                    <p className=''>1) Visita el sitio de Healy a través de nuestros links de afiliado:</p>
                                    <div className="mb-4">
                                        <a href="https://mexico.healy.shop/?partnername=3181-5897-3368" target={"_blank"}><button className="btn btn-outline-primary me-3">Healy México 🇲🇽</button></a>
                                        <a href="https://us.healy.shop/?partnername=3181-5897-3368" target={"_blank"}><button className="btn btn-outline-primary me-3">Healy USA 🇺🇸</button></a>
                                        <a href="https://eu.healy.shop/?partnername=3181-5897-3368" target={"_blank"}><button className="btn btn-outline-primary me-3">Healy Europe 🇪🇺</button></a>
                                        <a href="https://canada.healy.shop/?partnername=3181-5897-3368" target={"_blank"}><button className="btn btn-outline-primary me-3">Healy Canada 🇨🇦</button></a>
                                    </div>
                                    <p className='mb-4'>2) En la nueva ventana, haz click en el botón:  <span className="badge text-bg-success p-2 ">SÍ, ESTE ES MI RECOMENDANTE</span></p>
                                    <p>3) Haz clic en <span className='badge cta-btn'>% DESCUENTO</span> en la barra de menú superior</p>
                                    <p className='mb-4'>4)  Selecciona tu dispositivo y presiona <span className='badge text-bg-success'>PEDIR AHORA</span></p>
                                    <p className='mb-4'>5) Presiona <span className='badge cta-btn'>AÑADIR AL CARRITO</span></p>
                                    <p className='mb-0'>6) Verás que un enlace (<span className='badge text-bg-light'>Ver carrito</span>) aparece junto al botón, presiónalo. </p>
                                    <p className='small'>También puedes hacer clic en el ícono del bolso de compras (<span className='badge text-bg-light'><i className="fa-solid fa-bag-shopping"></i></span>) en la esquina superior derecha.</p>
                                    <p className='mb-4'>7) Presiona <span className='badge text-bg-dark'>PASAR POR CAJA</span></p>
                                    <p className='mb-4'>8) Si ingresaste con nuestros links de afiliado, verás la sección "TIPO DE CUENTA" debajo de "¿YA ERES CLIENTE?"</p>
                                    <p className='mb-4'>9) Omite la sección "¿YA ERES CLIENTE?" y dirígete a la sección "TIPO DE CUENTA". Selecciona "Hazte miembro independiente de Healy World" (¡Te da más beneficios!)</p>
                                    <p className='mb-4'>10) Luego, llena las secciones restantes con tu información personal (Nombre, e-mail, nombre de usuario para la creación de tu cuenta y dirección donde quieres que llegue tu dispositivo). Asegúrate que tu email y dirección estén bien escritos. <span className="text-decoration-underline">(Recuerda guardar estos datos.)</span></p>
                                    <div className="alert alert-warning p-2"><p>
                                        Sugerencia para nombre de usuario:  Nombre.Apellido  (Ej. Pablo.Martinez ó PabloMartinez1979)
                                    </p>
                                        <p className='m-0 fst-italic'>*El sistema respeta mayúsculas y minúsculas. Admite punto y números también. Pero NO caracteres especiales. </p>
                                    </div>
                                    <p className='mb-4'>11)  En método de pago elige el que desees. (Hay diferentes métodos de pagos según el país de la tienda de Healy.)</p>
                                    <p className='mb-4'>12) Por último, seleccione las 3 casillas para aceptar términos y condiciones y luego presione <span className='badge cta-btn'>PAGAR AHORA</span></p>
                                    <p className='mb-4'>13) Al terminar el pago, favor de enviarnos un <a href="https://wa.link/wcgyyl" target={"_blank"}>mensaje por WhatsApp</a> para darle los siguientes beneficios:
                                        <ul>
                                            <li>Manual de healy (pdf)</li>
                                            <li>Video y Manual de primeros pasos (pdf)</li>
                                            <li>Acceso a los grupos de WhatsApp y Telegram de apoyo.</li>
                                            <li>Acceso a capacitaciones via zoom para principiantes, usuarios intermedios y avanzados.</li>
                                            <li>Programa de apoyo para la recuperación de su inversión.</li>
                                        </ul></p>

                                </div>

                            </div>
                        </div>
                        <div className="modal-footer d-flex flex-column align-items-start">
                            <div className="alert alert-info">
                                <p className='h5'>Ver instrucciones en video: <a href="https://www.youtube.com/watch?v=suonb359WD0">Aquí</a></p>
                            </div>
                            <p className='text-start'>Si tienes dudas, contáctanos vía WhatsApp haciendo click <a href="https://wa.link/wcgyyl" target={"_blank"}>aquí</a>.</p>
                        </div>
                    </div>
                </div>
            </div>




        </section>
    )
}

export default Section33