import React from 'react'

function Header() {
  return (
    <header
    className="row  d-flex align-items-center px-4"
    style={{ height: "10vh" }}
  >
    <nav className="d-flex align-items-center text-light fs-5">
      <div className=" flex-grow-1 text-start">
        {/* <p className="m-0 h4"><i className="fa-solid fa-heart-pulse"></i> HEALY</p> */}
        <img className=' m-0' src="../../healy_logo_member.png" alt="" style={{height: "90px"}} />
      </div>
      <a className="d-none d-sm-inline small text-end me-3 text-decoration-none text-light" href="#about">¿Qué es Healy?</a>
      <a className="d-none d-sm-inline small text-end me-3 text-decoration-none text-light" href="#about-us">Nosotras</a>
      <a className="d-none d-sm-inline small text-end me-3 text-decoration-none text-light" href="#shop">Productos</a>
      <a className="d-none d-sm-inline small text-end me-3 text-decoration-none text-light" href="#testimony">Testimonios</a>
      <a className="d-none d-sm-inline small text-end me-3 text-decoration-none text-light" href="#eventos">Eventos</a>
      <a className="d-none d-sm-inline small text-end me-3 text-decoration-none text-light" href="#blog">Blog</a>
      <a className="d-none d-sm-inline small text-end me-3 text-decoration-none text-light" href="#faq">FAQ</a>
      <div className="d-none d-sm-inline small text-end mx-3">|</div>
      <div className="m-0 small text-end">
        <a href="https://wa.link/wcgyyl" target={"_blank"}  className=" h6 text-decoration-none fw-bolder">Contáctanos</a>
        </div>

        <div className="dropdown ms-4 d-block d-sm-none">
          <button className="btn btn-secondary btn-lg" data-bs-toggle="dropdown"><i className="fa-solid fa-bars"></i></button>
          <ul className="dropdown-menu dropdown-menu-dark">
            <li><a className='dropdown-item' href="#about">¿Qué es Healy?</a> </li>
            <li><a className='dropdown-item' href="#about-us">Nosotras</a> </li>
            <li><a className='dropdown-item' href="#shop">Productos</a> </li>
            <li><a className='dropdown-item' href="#testimony">Testimonios</a> </li>
            <li><a className='dropdown-item' href="#eventos">Eventos</a> </li>
            <li><a className='dropdown-item' href="#blog">Blog</a> </li>
            <li><a className='dropdown-item' href="#faq">FAQ</a> </li>
          </ul>
        </div>
    </nav>
  </header>
  )
}

export default Header