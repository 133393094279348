import React, {useState, useEffect} from 'react'

function Section52() {
    const [data, setData] = useState([])
    const [sPost, setSPost] = useState({})
  
    useEffect(() => {
      fetch(`https://us-central1.gcp.data.mongodb-api.com/app/healy-ckncs/endpoint/readBlog`, { method: "GET" })
        .then(response => response.json())
        .then(response => setData(response))
    }, [])
  
    const handlePost = (article) => {
      setSPost(article)
    } 


  return (
    <section
      className=" row d-flex flex-column justify-content-center px-2 px-sm-5 my-5"
      style={{ minHeight: "90vh" }}
      id="blog"
    >
      <div className="col-12">
        <p className="text-start text-light display-2 fw-semibold my-5">
          Blog
        </p>
      </div>

      {/* <div className=" col-12 border rounded-4 text-light d-flex align-items-center justify-content-center flex-column text-center" style={{ aspectRatio: "16/9", maxHeight: "500px", backgroundImage:"linear-gradient(180deg,)" }}>

        <p className="h2 mb-3">Visita nuestro canal de Telegram</p>
        <p className="">¡Entérate de los próximos talleres, pláticas y retos para que conozcas Healy y Maghealy!</p>
        <p className="mb-5">¡Además de noticias y mucho más!</p>


        <a href="https://t.me/eventoshealy" target={"_blank"}>
          <button className='btn btn-primary btn-lg'><i className="fa-solid fa-paper-plane"></i> /eventoshealy</button>
        </a>
      </div> */}


       <div className="row">
           {data && data.map((article, key) => {
            let iconPost = ""
            switch (article.tipo) {
              case "anuncio":
                iconPost = "bullhorn"
                break;
              case "taller":
                iconPost = "people-roof"
                break;
              case "oferta":
                iconPost = "certificate"
                break;
              case "reto":
                iconPost = "flag-checkered"
                break;
              default:
                break;
            }
            return (
              <div className="col-sm-4 align-self-stretch  mb-4" key={key} data-bs-toggle="modal" data-bs-target="#modal-blog" onClick={() => handlePost(article)}>
                <div className="card border-0" style={{cursor: "pointer"}}>
                  <div
                    height="100%"
                    className={`card-img-top ${article.tipo} d-flex justify-content-center align-items-center`}
                    alt="..."
                    style={{ height: "70px", objectFit: "cover" }}>
                    <p className='text-light h2'>
                      <i className={`fa-solid fa-${iconPost} me-3`}></i>
                      {article.tipo}
                    </p>
                  </div>
                  <div className="card-body text-start">
                    <p className="h3 card-title">{article.título}</p>
                    <p className="h5 mb-3"><i className="fa-regular fa-circle-user me-2"></i>{article.autor} - {new Date(article.fecha).toLocaleDateString()} </p>
                    <p className="card-text h6 text-truncate">
                      {article.contenido}
                    </p>
                  </div>
                </div>
              </div>
            )
                 })} 
           
                 {/* MODAL BLOG POST */}
                 <div className="modal fade text-dark" id="modal-blog" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className={`modal-header text-light ${sPost.tipo}`}>
                  
                  <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <h1 className="modal-title h3 mb-4" id="exampleModalLabel">{sPost.título}</h1>
                  <p className='h5 mb-4'><i className="fa-regular fa-circle-user me-2"></i>{sPost.autor} - {new Date(sPost.fecha).toLocaleDateString()}</p>
                  <p className='h6'>{sPost.contenido}</p>
                </div>
              </div>
            </div>
                 </div>
       </div>


    </section>
  )
}

export default Section52